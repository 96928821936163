@import "~bootstrap/scss/bootstrap";

body {
    font-family: Consolas, Lucida Console, Courier New, serif;
    /* padding: 10px; */
}

span.heading {
    font-size: 140%;
}

span.heading2 {
    font-size: 130%;
}

span.layer {
    font-size: 120%;
    font-weight: bold;
    background-image: url("/images/bullet_blue.png");
    padding-left: 30px;
    background-repeat: no-repeat; /* Do not repeat the image */
}

span.device {
    font-size: 110%;
    background-image: url("/images/bullet_green.png");
    padding-left: 40px;
    background-position: 10px;
    background-repeat: no-repeat; /* Do not repeat the image */
}

a,
.link {
    cursor: pointer;
}

a:hover.link-nohover {
    text-decoration: none;
    cursor: pointer;
}

a,
a:visited {
    color: #4d6d9a;
    text-decoration: none;
    font-weight: bold;
}

.profile {
    span {
        padding: 0 4px;
    }
    img {
        max-width: 30px;
        max-height: 30px;
        vertical-align: middle;
    }
}

.control {
    padding-right: 4px;
    img {
        vertical-align: middle;
    }
}

.option {
    img {
        vertical-align: middle;
        padding-right: 4px;
    }
}

.based-on {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.layerList {
    /* position: fixed; */

    li.device {
        list-style-type: none;
        padding-right: 10px;
        float: left;
        cursor: pointer;
    }
    li.layer {
        list-style-image: url("/images/bullet_blue.png");
        cursor: pointer;
        clear: both;
    }
    li.layerAnchor {
        list-style-image: url("/images/anchor.png");
        cursor: pointer;
        clear: both;
    }
}

pre {
    padding: 10px;
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.alert-content {
    text-align: left;
}
